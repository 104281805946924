'use client';

import { CONNECT_DUPR_MODAL_KEY, DEFAULT_EXPIRATION } from '@/components/ConnectDUPRModal/constants';

export const getShouldShowModal = (userConnectedDUPR: boolean, loggedIn: boolean) => {
	if (userConnectedDUPR || !loggedIn) {
		deleteShouldShowModal();
		return false;
	}

	const itemStr = localStorage.getItem(CONNECT_DUPR_MODAL_KEY);

	if (!itemStr) {
		return true;
	}

	try {
		const item = JSON.parse(itemStr);

		const now = new Date();

		if (item.expiry < now.getTime()) {
			return true;
		}

		return false;
	} catch (e) {
		setShouldShowModal();
		return true;
	}
};

export const setShouldShowModal = (expiresIn = DEFAULT_EXPIRATION) => {
	const now = new Date();

	const item = {
		value: 'true',
		expiry: now.getTime() + expiresIn
	};

	localStorage.setItem(CONNECT_DUPR_MODAL_KEY, JSON.stringify(item));
};

export const deleteShouldShowModal = () => {
	localStorage.removeItem(CONNECT_DUPR_MODAL_KEY);
};
