import { useMutation } from '@tanstack/react-query';

import { AddImpressionBody } from '@/types/impressionsTypes';
export interface ImpressionRes {
	message: string;
	statusCode?: number;
}
const addImpression = async (data: AddImpressionBody): Promise<ImpressionRes> => {
	const bodyData = JSON.stringify(data);

	const response = await fetch(`/api/v2/impressions/add-impression`, {
		method: 'POST',
		body: bodyData
	});
	const responseData: ImpressionRes = await response.json();
	return responseData;
};

export const updateImpressions = async (userKey: string): Promise<ImpressionRes> => {
	const bodyData = JSON.stringify({ user_key: userKey });
	const response = await fetch(`/api/v2/impressions/patch-activity-on-login`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		},
		body: bodyData
	});

	const responseData: ImpressionRes = await response.json();
	return responseData;
};

export const useAddImpressionMutation = () => useMutation(addImpression);
