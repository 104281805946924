const NEW_NEXT_PUBLIC_IMAGE_CDN = 'https://cdn.pickleball.com';

export default function getCDNURL(url: string, width: number, height: number, saturation?: number): string {
	const NEXT_PUBLIC_IMAGE_CDN = process.env.NEXT_PUBLIC_IMAGE_CDN;

	if (NEXT_PUBLIC_IMAGE_CDN && url && url.startsWith(NEXT_PUBLIC_IMAGE_CDN)) {
		const basePath = url.replace(NEXT_PUBLIC_IMAGE_CDN, NEW_NEXT_PUBLIC_IMAGE_CDN);
		const sizeQuery = width && height ? `?width=${width}&height=${height}&optimizer=image${saturation ? `&saturation=${saturation}` : ``}` : '';

		return `${basePath}${sizeQuery}`;
	}

	return url;
}
